import axios from "axios";
import FeatherIcon from "feather-icons-react";
import $ from "jquery";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../../http/backend-base-url";
import api from "../../http/global-vars";
import { hideModal } from "./ItemSessionModule";
import ContentEditor from "./supportContentAdmin/ContentEditor";

toast.configure();
export default class MdalAddModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalId: props.modalId,
      api: api,
      module: props.module,
      fields: {
        description: props.module?.description || "",
        name: props.module?.name || "",
        has_certicate: props.module?.has_certicate || false,
      },
      load: false,
      errors: {},
      isInvalidFileExtension: false,
      inputFileLabel: "Uploader une image",

      is_empty_name: false,
      is_empty_description: false,
      is_empty_logo: false,
    };
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "logo") {
      if (this.fileValidation(e.target.files[0])) {
        fields[e.target.name] = e.target.files[0];
        this.setState({
          inputFileLabel: fields.logo.name,
        });
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 5000);
      }
    } else if (e.target.name === "scorm_file") {
      fields[e.target.name] = e.target.files[0];
    } else {
      fields[e.target.name] = e.target.value;
    }
    // console.log(fields);
    this.setState({ fields: fields }, () => console.log(fields));
  };

  hasErrorFor = (field) => {
    return !!this.state.errors[field];
  };

  renderErrorFor = (field) => {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback">
          <strong>{this.state.errors[field]}</strong>
        </span>
      );
    }
  };

  fileValidation = (file) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(file?.name)) {
      return false;
    } else {
      return true;
    }
  };

  notify = () => {
    toast.success(
      `Module ${this.state.module ? "modifié" : "ajouté"} avec succès`,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      }
    );
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
          errors: {},
        });
      }, 5000);
      errors["name"] = "Ce champ est obligatoire";
    }
    if (!fields["description"]) {
      isValidForm = false;
      this.setState({
        is_empty_description: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_description: false,
          errors: {},
        });
      }, 5000);
      errors["description"] = "Ce champ est obligatoire";
    }

    if (!this.state.module && !this.fileValidation(this.state.fields.logo)) {
      isValidForm = false;
      this.setState({
        is_empty_logo: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_logo: false,
          errors: {},
        });
      }, 5000);
      errors["logo"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  renderBooldField(name, label) {
    return (
      <div className="col-md-6">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            name={name}
            id={name}
            checked={!!this.state.fields[name]}
            onChange={(e) =>
              this.setState({
                fields: {
                  ...this.state.fields,
                  [e.target.name]: e.target.checked,
                },
              })
            }
            className=""
          />{" "}
          <label className="" htmlFor={name}>
            {label}
          </label>
        </div>
      </div>
    );
  }

  onSubmit = async (e) => {
    e.preventDefault();

    //console.log("data sended ==>", data)

    if (this.onValidateFormData()) {
      this.setState({ load: true, errors: {} });
      let { api, fields } = this.state;
      let url = api + "module/";
      let data = { ...fields };
      data.creator_id = localStorage.userID;
      try {
        let fd = new FormData();
        fd.append("name", data.name);
        fd.append("description", data.description);
        fd.append("has_certicate", data.has_certicate);
        if (data.logo) {
          fd.append("logo", data.logo);
        }

        if (data.scorm_file) {
          fd.append("scorm_file", data.scorm_file);
        }
        fd.append("creator_id", data.creator_id);
        if (this.state.module) {
          const res = await axios.put(url + this.state.module.id + "/", fd, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          });
          this.setState({ module: res.data });
        } else {
          await axios.post(url, fd, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          });
          this.setState({
            fields: {
              description: "",
              logo: null,
              name: "",
              scorm_file: null,
            },
          });
          e?.target?.reset()
        }
        this.props.getModules();
        $("#module-image").attr("value", "");
        this.setState({ load: !this.state.load, fields: {} });
        this.notify();
        hideModal(`#${this.state.modalId}`);
      } catch (error) {
        this.setState({ load: !this.state.load });
        let status = error?.response?.status;
        let data = error?.response?.data;
        if (status === 400) {
          let error = {};
          for (let [key, value] of Object.entries(data)) {
            error = {
              [key]: [value],
            };
            this.setState({ errors: error });
          }
          toast.error("Veuillez renseigner le titre du module", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      }
    }
  };

  render() {
    let { load } = this.state;
    return (
      <div>
        <h5 className="text-black m-b-50">
          {this.state.module ? "Modifier" : "Ajouter"} un module
        </h5>
        <form onSubmit={this.onSubmit} id="fieldreset">
          <div className="row">
            <div className="col-md-6 ">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  value={this.state.fields.name}
                  className={`form-control input-dashboard-backend text-black ${
                    this.hasErrorFor("name") ? "is-invalid" : ""
                  }`}
                  placeholder="Titre du module"
                />
                {this.renderErrorFor("name")}
              </div>
            </div>
            {this.renderBooldField("has_certicate", "Module avec certificat ?")}
            <div className="col-md-12 m-b-40">
              <div className="form-group">
                <label htmlFor="module-image">Image du module</label>
                <div>
                  <FeatherIcon
                    className="icon-import-custom-file-label"
                    style={{ top: 50 }}
                    icon="upload"
                  />
                  <input
                    type="file"
                    id="module-image"
                    style={{ paddingTop: 10 }}
                    name="logo"
                    accept="image/*"
                    onChange={this.handleChange}
                    className={`form-control input-dashboard-backend text-black mt-2 ${
                      this.hasErrorFor("logo") ? "is-invalid" : ""
                    }`}
                    placeholder="Image du module"
                  />
                </div>
                {this.renderErrorFor("logo")}
                {this.state.isInvalidFileExtension && (
                  <div className="alert alert-danger gfa-alert-danger import-danger">
                    Ce format de fichier n'est pas accepté. Veuillez charger un
                    fichier au format image
                  </div>
                )}
              </div>
              {this.state.module && (
                <img
                  src={baseUrl + this.state.module.logo}
                  alt=""
                  style={{ width: 120, height: 120 }}
                />
              )}
            </div>
            <div className="col-md-12 m-b-40">
              <div className="form-group">
                <label htmlFor="module-scorm">Fichier scorm</label>
                <div>
                  <FeatherIcon
                    className="icon-import-custom-file-label"
                    style={{ top: 50 }}
                    icon="upload"
                  />
                  <input
                    type="file"
                    id="module-scorm"
                    style={{ paddingTop: 10 }}
                    name="scorm_file"
                    accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                    onChange={this.handleChange}
                    className={`form-control input-dashboard-backend text-black mt-2 ${
                      this.hasErrorFor("scorm_file") ? "is-invalid" : ""
                    }`}
                    placeholder="Fichier scorm"
                  />
                </div>
                {this.renderErrorFor("scorm_file")}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group m-b-40">
                <ContentEditor
                  value={this.state.fields.description}
                  placeholder={"Description"}
                  name={`description_${this.state.modalId}`}
                  onChange={(value) => {
                    this.setState({
                      fields: { ...this.state.fields, description: value },
                    });
                  }}
                />
                {/* {!!this.hasErrorFor("description") && ( */}
                  <span className="invalid-feedback">
                    <strong>{this.state.errors["description"]}</strong>
                  </span>
                {/* )} */}
              </div>
              {/* {this.renderErrorFor("description")} */}
            </div>
            <div className="col-md-6 offset-md-3 m-t-30">
              {!load ? (
                <button className="btn-submit-form">
                  {this.state.module ? "Modifier" : "Ajouter"}
                </button>
              ) : (
                <button className="btn-submit-form">
                  Chargement... &nbsp;<i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
