/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { withScorm } from "react-scorm-provider";

function ScormContentAdmin(props) {
  const { module } = props;
  const iframeRef = useRef(null);

  useEffect(() => {
    // Récupérer la référence de l'iframe
    const iframe = document.getElementById("targetIframe");

    // Envoyer un message à l'iframe
    iframe.contentWindow.postMessage(
      "Message de la fenêtre parent",
      "https://andu-scorm.withvolkeno.com"
    );
    // Écouter les messages provenant de la fenêtre parent
    window.addEventListener("message", (event) => {
      console.log("event",event)
      // Vérifier l'origine du message pour des raisons de sécurité
      if (event.origin === "https://andu-gfa-web.withvolkeno.com") {
        // Traitement du message reçu
        console.log("Message reçu dans l'iframe :", event.data);
      } else {
        console.error("Message rejeté en raison de l'origine non autorisée.");
      }
    });
  }, [iframeRef.current, props]);

  const handleIframeLoad = () => {
    const iframe = document.getElementById("targetIframe");
    // const iframeContent = iframe.contentWindow.document;

    // // Remplacez 'example.jpg' par le sélecteur approprié pour votre image
    // const imageElement = iframeContent.querySelector('img[src="example.jpg"]');

    // if (imageElement) {
    //   const imageSrc = imageElement.getAttribute('src');
    //   // setImageUrl(imageSrc);
    // }
    console.log("iframeWindow", iframe, iframe?.contentWindow);
  };
  return (
    <div className="card-item-sessions-module contenu-shadow">
      <span className="link-collapse-session-item ">{module?.name}</span>
      <div className="card card-body p-t-50 m-b-0">
        <div className="row dis-flex justify-content-center">
          <div className="col-md-12 offset-md-0">
            <div class="scorm-container">
              <iframe
                src={module?.scorm_link}
                frameBorder="0"
                ref={iframeRef}
                title={module?.name}
                className="scorm-content"
                loading="lazy"
                id="targetIframe"
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withScorm()(ScormContentAdmin);
