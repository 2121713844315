import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import FeatherIcon from "feather-icons-react";
// import LeconCard from "../components/LeconCard";
import { NavLink } from "react-router-dom";
import api from "../../http/global-vars";
// import { calcTime } from "../../utils";
import axios from "axios";
import DetailSessionSkeleton from "../components/DetailSessionSkeleton";
import MessageAllPage from "../../backend/components/MessageAllPage";
import ItemSessionDetailsPage from "../components/ItemSessionDetailsPage";
import baseUrl from "../../http/backend-base-url";
import ScormContentAdmin from "../../backend/components/ScormContentAdmin";
import ScormProvider from "react-scorm-provider";
import { withRouter } from "react-router-dom";

class SessionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: window.localStorage.getItem("userToken"),
            requestConfig: {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                },
            },
            api: api,
            userID: window.localStorage.getItem("userID"),
            moduleId: this.props.match.params.id,
            module: {},
            sessions: [],
            loading: false,
            baseUrl: baseUrl,
            usermodules: [],
            isDoing: false,
        };
    }

    componentDidMount() {
        window.localStorage.setItem("moduleId", this.state.moduleId);
        this.setState({ loading: true });
        this.getModuleById();
        this._getCOntinu();
        const timer = setTimeout(() => {
            this.getSessionByModule();
        }, 3000);
        // Cancel the timer while unmounting
        return () => clearTimeout(timer);
    }

    getModuleById = () => {
        const url = `${api}module/${this.state.moduleId}`;
        axios
            .get(url, this.state.requestConfig)
            .then((response) => {
                // console.log("response", this.props);
                if (response?.data?.scorm_link) {
                    window.open(response?.data?.scorm_link)
                    this.props.history.goBack()
                }
                this.setState({
                    module: response.data,
                    loading: false,
                });
                this.userModules();
            })
            .catch((e) => {
                console.log("e", e);
            });
    };

    userModules = () => {
        let checker = (arr) =>
            arr.every(
                (v) => parseInt(v?.module?.id) !== parseInt(this.state?.moduleId)
            );
        const url = api + "usermodules/" + parseInt(this.state.userID) + "/";
        axios
            .get(url, this.state.requestConfig)
            .then((data) => {
                //console.log("module user", checker(data.data.data), this.state?.moduleId);
                if (checker(data.data.data) !== false) {
                    this.setState({ isDoing: false });
                } else {
                    this.setState({ isDoing: true });
                }
                this.setState({ usermodules: data.data.data });
            })
            .catch((e) => {
                console.log("error", e.response);
            });
    };

    userDoModule = () => {
        let checker = (arr) =>
            arr.every(
                (v) => parseInt(v?.module?.id) !== parseInt(this.state?.moduleId)
            );
        console.log("check", checker(this.state.usermodules));
        if (this.state.isDoing === false) {
            axios
                .post(
                    api + "userdomodule/",
                    {
                        progression: 0,
                        user: parseInt(this.state.userID),
                        module: parseInt(this.state?.moduleId),
                    },
                    this.state.requestConfig
                )
                .then((data) => {
                    console.log("data", data.data);
                })
                .catch((e) => {
                    console.log("e", e.response);
                });
        }
        window.scrollTo(0, 0);
    };

    _getCOntinu = async () => {
        let { api, requestConfig } = this.state;
        let url = api + `usercontenus/${localStorage.userID}/`;
        try {
            let req = await axios.get(url, requestConfig);
            // console.log(req);
            let data = req["data"];
            // eslint-disable-next-line no-unused-vars
            let filterModule = data
                .map((item) => item.contenu)
                .filter((c) => c.module === this.state.moduleId);
            //console.log(filterModule);
        } catch (error) {
            console.log(error.response);
        }
    };

    getSessionByModule = async () => {
        const url = `${api}sessionByModule/${this.state.moduleId}`;
        try {
            const res = await axios.get(url, this.state.requestConfig);
            if (res["status"] === 201 || res["status"] === 200) {
                this.setState({ session: res?.data?.sort((a, b) => a?.id - b?.id) });
                //console.log(res['data']);
            }
        } catch (error) {
            console.log(error.message);
            console.log(error.response);
        }
    };

    render() {
        const { module, loading, baseUrl } = this.state;
        //console.log('isDoing', this.state.isDoing)
        return (
            <ScormProvider version="2004">
                <div className="container-fluid body-background-dashboard">
                    <div className="row">
                        {/* Navbar Mobile */}
                        <div className="col-xl-2 col-lg-3 col-md-3 col-lg-2 col-md-12 no-padding no-view-desktop">
                            <NavbarDashboardMobile />
                        </div>
                    </div>

                    <HeaderDashboard />

                    <div className="row">
                        {/* Navbar Desktop  */}
                        <div className="col-xl-2 col-lg-3 col-md-12">
                            <div className="navbar-vertical-customisation no-view-mobile">
                                <NavbarVertical />
                            </div>
                        </div>
                        {loading ? (
                            <DetailSessionSkeleton />
                        ) : (
                            <div className="col-xl-10 col-lg-9 col-lg-9 col-md-12 content-position-after-header p-b-200">
                                <div className="">
                                    <div className="card">
                                        <div className="banner-image-cours-details">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <p className="text-banner-image-cours-details">
                                                        {module?.name}
                                                    </p>

                                                    <div className="d-flex">
                                                        <FeatherIcon
                                                            className="icon-details-infos-cours"
                                                            icon="align-justify"
                                                        />
                                                        <p className="text-details-infos-cours">
                                                            <span className="fw-600">
                                                                {module?.number_session} Sessions
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 offset-md-1 itm-center">
                                                    <img
                                                        className="w-full"
                                                        src={
                                                            module?.logo
                                                                ? baseUrl + module?.logo
                                                                : "/img/intersect.png"
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content-cours-details">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <div className="text-black m-b-100 fs-14 fw-300">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: module?.description,
                                                            }}
                                                        />
                                                    </div>
                                                    {!module?.scorm_link && (
                                                        <div className="row m-b-30 dis-flex">
                                                            <div className="col m-b-20">
                                                                <NavLink
                                                                    onClick={this.userDoModule}
                                                                    className="btn-theme text-up"
                                                                    to={`/session-start/${"module"}/${module?.id}`}
                                                                >
                                                                    {this.state.isDoing === true
                                                                        ? "Continuer le module"
                                                                        : "Commencer le module"}
                                                                </NavLink>
                                                            </div>
                                                            {/* <div className="col-xl-4 col-lg-5 col-md-5 ml-auto">
                            <a className="btn-theme-light" href=""><FeatherIcon className="icon-button" icon="download" />Télécharger le cours</a>
                        </div> */}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* start scorm */}
                                    {!!module?.scorm_link && <ScormContentAdmin module={module} />}
                                    {/* end scorm */}
                                    {!module?.scorm_link && (
                                        <>
                                            <div className="item-session-details-page">
                                                <h6 className="text-up text-dark f-f-work fw-600">
                                                    <NavLink
                                                        className="link-details-sessions-on-title"
                                                        to={`/session-start/${"module"}/${module.id}`}
                                                    >
                                                        Introduction
                                                    </NavLink>
                                                </h6>
                                                <NavLink
                                                    className="link-show-on-item-session-details-page"
                                                    to={`/session-start/${"module"}/${module.id}`}
                                                >
                                                    <FeatherIcon
                                                        className="icon-show-on-item-session-details-page"
                                                        icon="chevron-right"
                                                    />
                                                </NavLink>
                                            </div>

                                            <p className="text-up text-black m-b-20 fs-12 fw-600 f-f-work">
                                                Toutes les sessions
                                            </p>

                                            <ItemSessionDetailsPage
                                                isDoing={this.state.isDoing}
                                                moduleId={this.props.match.params.id}
                                            />

                                            <div className="dis-flex m-t-50">
                                                <NavLink
                                                    onClick={this.userDoModule}
                                                    className="btn-theme text-up"
                                                    to={`/session-start/${"module"}/${module.id}`}
                                                >
                                                    {this.state.isDoing === true
                                                        ? "Continuer le module"
                                                        : "Commencer le module"}
                                                </NavLink>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="">
                        <MessageAllPage />
                    </div>
                </div>
            </ScormProvider>
        );
    }
}

export default withRouter(SessionDetails);