// RECETTE API BASE URL
// var baseUrl = "https://www.andu-gfa-backend.withvolkeno.com";

// ONLINE API BASE URL
// var baseUrl = 'https://backend.defaru.sn'

// DEMO API BASE URL
var baseUrl = "https://demo-api.defaru.sn";

export default baseUrl;
